import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from  '@material-ui/core/styles';
import { Typography, Tooltip,Link,Grid,Avatar, CardHeader } from '@material-ui/core';
import { Apple as AppStore, PlayArrow as PlayStore, Android as AndroidIcon}from '@material-ui/icons';
import { green,blueGrey } from '@material-ui/core/colors';

import Box from '@material-ui/core/Box';
import Fab from '@material-ui/core/Fab';
import MessageIcon from '@material-ui/icons/Message';
const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingRight: theme.spacing(4)
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: blueGrey[400]
  }
}));

const Footer = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
  
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
  {/*<Box sx={{ '& > :not(style)': { m: 1 } }}>
  <Link
                component="a"
                href={'/chatbox'} >
    <Tooltip title="Chat">
    <Fab color="primary" aria-label="Chat">
      <MessageIcon />
    </Fab>
    
    </Tooltip>
    </Link>
    </Box>*/}
    <Grid container spacing={2}>
        <Grid item md={3} xs={6} >
        <CardHeader
        title={
        <Link component="a"
        href="https://www.gigagates.com" target="_blank" >
        © Giga Gates. {new Date().getFullYear()}
        </Link>
        }
        subheader={
        <Link component="a"
                href="mailto:support@gigagates.com" target="_blank" >
        support@gigagates.com
        </Link>
        }/>
        {/*
            <Typography variant="body1">
                &copy;{' '}
                <Link
                  component="a"
                  href="https://www.quickq-innovation.com"
                  target="_blank"
                >
                  QuickQ Innovation
                </Link>
                . {new Date().getFullYear()}
              </Typography>
              <Typography variant="caption">
                support@quickq-innovation.com
            </Typography>
*/}
        </Grid>

        <Grid item md={3} xs={6} >
            <Link
                component="a"
                href="https://apps.apple.com/us/app/itunes-connect/id376771144"
                target="_blank">
            <CardHeader
                title={'Download on App Store'}
                avatar={<Avatar alt="App store" className={classes.small}> <AppStore /> </Avatar>} />
            </Link>
        </Grid>

        <Grid item md={3} xs={6} >
            <Link
                component="a"
                href="https://play.google.com/store/apps/details?id=com.quickq.service.qq_delivery"
                target="_blank">
            <CardHeader
                title={'Download on Play Store'}
                avatar={<Avatar alt="Play store" className={classes.small}> <PlayStore /> </Avatar>} />
            </Link>
        </Grid>

        <Grid item md={3} xs={6} >
            <Link
                component="a"
                href="https://gigagates.com/files/konesi/kone-si-28-09-2023.apk"
                target="_blank">
            <CardHeader
                title={'Download Android APK'}
                avatar={<Avatar alt="Play store" className={classes.small}> <AndroidIcon /> </Avatar>} />
            </Link>
        </Grid>
    </Grid>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;